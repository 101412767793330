import { AISettings } from '../components/Settings/AISettings';

export function OrganizationAISettingsRoute() {
  return <AISettings className='pl-8 pt-10 pr-12.5' />;
}

export const Component = OrganizationAISettingsRoute;

export function clientLoader() {
  return null;
}
